import React, { useContext, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import router from "src/router";
import AuthContext from "src/context/Auth";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "./theme";
import { Toaster } from "react-hot-toast";
import { Buffer } from "buffer";

function App() {
  const theme = createTheme();

  global.Buffer = Buffer;

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Toaster position="top-right" reverseOrder={true} />
          <AuthContext>
            <RouterProvider router={router} />
          </AuthContext>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
